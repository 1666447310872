import styled from "styled-components";

const NoReplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.01);
  border-radius: 16px;
  width: 250px;
  height: 100%;
  min-height: 200px;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.comp4.normal};
  user-select: none;
`;

export default NoReplayWrapper;
