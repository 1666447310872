import { Box } from "@mantine/core";
import {
  IconAward,
  IconBuildingWarehouse,
  IconCake,
  IconCar,
  IconChartCircles,
  IconEdit,
  IconFileInvoice,
  IconMap,
  IconMessage,
  IconReport,
  IconSchool,
  IconTestPipe,
  IconTrophy,
} from "@tabler/icons";
import { NavLink } from "react-router-dom";
import {
  ADMIN_BILLING_PATH,
  ADMIN_CONTESTS_PATH,
  ADMIN_PATH,
  ADMIN_TEACHER_LECTURES_PATH,
  ADMIN_TEACHER_LECTURES_STATS_PATH,
  BETA_SCHOOLS_PATH,
  TICKETS_PATH,
  COURSE_EDITOR_STATS_PATH,
  EXERCISE_COMMENTS_PATH,
  GLOBAL_TOPLIST_PATH,
  GLOBAL_USERS_PATH,
  MAP_PATH,
  ORGANIZATIONS_PATH,
  RUN_DATA_PATH,
  SCHOOLS_PATH,
  STATS_SIMULATORS_ACTIVATION_PATH,
  STATS_SIMULATORS_PATH,
} from "../../../../navigation/Routes";
import {
  SubMenuContent,
  SubMenuWrapper,
} from "../../../global/Misc/Misc.styled.components";
import {
  IconOrganization,
  IconPerson,
} from "../../../global/Misc/Symbols.components";
import { BigNumberWrapper } from "./Admin.styled.components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";

interface SubMenuSimulatorsIconProps {
  className?: string;
  color?: string;
}

export const SubMenuSimulatorsIcon = (props: SubMenuSimulatorsIconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.85442 0L13.7088 6.85441L6.85442 13.7088L0 6.85441L6.85442 0ZM9.44012 6.64275C9.16884 6.4517 8.76919 6.35618 8.24116 6.35618H6.0126C5.70257 6.35618 5.54754 6.2631 5.54754 6.07695C5.54754 5.92999 5.59356 5.82712 5.6856 5.76834C5.77764 5.70466 5.92541 5.67281 6.12887 5.67281H8.11575L9.30744 4.39427H6.47039C6.04409 4.39427 5.68075 4.43836 5.38041 4.52653C5.08006 4.61471 4.83301 4.73962 4.63924 4.90128C4.45031 5.05803 4.31225 5.24418 4.22505 5.45972C4.13785 5.67036 4.09425 5.9006 4.09425 6.15043C4.09425 6.50803 4.22263 6.7946 4.47937 7.01014C4.73612 7.22569 5.14062 7.33346 5.69287 7.33346H7.92871C8.2339 7.33346 8.38649 7.43143 8.38649 7.62737C8.38649 7.76944 8.34047 7.87476 8.24843 7.94334C8.15639 8.01192 8.00864 8.04621 7.80518 8.04621H5.13394L3.57612 9.31006H7.46366C7.8948 9.31006 8.26054 9.26597 8.56088 9.1778C8.86607 9.08472 9.11313 8.95736 9.30206 8.7957C9.49583 8.62915 9.63631 8.4332 9.72351 8.20786C9.81071 7.98253 9.8543 7.73759 9.8543 7.47307C9.8543 7.10567 9.71624 6.82889 9.44012 6.64275Z"
      className="fill"
      fill={props.color}
    />
  </svg>
);

interface SubMenuStatsIconProps {
  className?: string;
  color?: string;
}

const SubMenuStatsIcon = (props: SubMenuStatsIconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 4C13.5523 4 14 4.44772 14 5V11.6667C14 12.219 13.5523 12.6667 13 12.6667C12.4477 12.6667 12 12.219 12 11.6667V5C12 4.44772 12.4477 4 13 4Z"
      className="fill"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4C1.55228 4 2 4.44772 2 5V11.6667C2 12.219 1.55228 12.6667 1 12.6667C0.447715 12.6667 0 12.219 0 11.6667V5C0 4.44772 0.447715 4 1 4Z"
      className="fill"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0C9.55228 0 10 0.447715 10 1V11.6667C10 12.219 9.55228 12.6667 9 12.6667C8.44772 12.6667 8 12.219 8 11.6667V1C8 0.447715 8.44772 0 9 0Z"
      className="fill"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5.66663C5.55228 5.66663 6 6.11434 6 6.66663V11.6666C6 12.2189 5.55228 12.6666 5 12.6666C4.44772 12.6666 4 12.2189 4 11.6666V6.66663C4 6.11434 4.44772 5.66663 5 5.66663Z"
      className="fill"
      fill={props.color}
    />
  </svg>
);

export const SubMenu = () => {
  const permissionsState = useSelector((state: RootState) => state.permissions);
  const viewConfig = permissionsState.loggedInUserViewConfig;
  const isAllowedBilling = viewConfig["ShowBillingPage"];
  return (
    <SubMenuWrapper className="subMenu">
      <SubMenuContent>
        <NavLink exact to={ADMIN_PATH}>
          <SubMenuStatsIcon />
          <label>Statistik</label>
        </NavLink>
        <NavLink exact to={STATS_SIMULATORS_PATH}>
          <SubMenuSimulatorsIcon />
          <label>Simulatorer</label>
        </NavLink>
        <Box
          exact
          component={NavLink}
          to={STATS_SIMULATORS_ACTIVATION_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconCake width={18} />
          <label>Nya simulatorer</label>
        </Box>
        <NavLink exact to={GLOBAL_USERS_PATH}>
          <IconPerson className="person" />
          <label>Användare</label>
        </NavLink>
        <NavLink to={ORGANIZATIONS_PATH}>
          <IconOrganization className="icon organization" />
          <label>Organisationer</label>
        </NavLink>
        <Box
          component={NavLink}
          to={SCHOOLS_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconBuildingWarehouse width={18} />
          <label>Skolor</label>
        </Box>
        <Box
          component={NavLink}
          to={BETA_SCHOOLS_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconTestPipe width={18} />
          <label>Beta-skolor</label>
        </Box>
        <Box
          component={NavLink}
          to={MAP_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconMap width={18} strokeWidth={2.1} className="icon map" />
          <label>Kartan</label>
        </Box>
        <Box
          component={NavLink}
          to={GLOBAL_TOPLIST_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconTrophy width={18} strokeWidth={2.1} className="icon map" />
          <label>Topplistan</label>
        </Box>
        <Box
          component={NavLink}
          to={TICKETS_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconReport className="icon bugs" />
          <label>Ärenden</label>
        </Box>
        <Box
          component={NavLink}
          to={RUN_DATA_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconCar className="icon" width={15} />
          <label>Kördata</label>
        </Box>
        <Box
          component={NavLink}
          to={COURSE_EDITOR_STATS_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconEdit className="icon" width={15} />
          <label>Skapade kurser</label>
        </Box>
        <Box
          component={NavLink}
          to={ADMIN_TEACHER_LECTURES_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconSchool className="icon" width={15} />
          <label>Lärarutbildningar</label>
        </Box>
        <Box
          component={NavLink}
          to={ADMIN_TEACHER_LECTURES_STATS_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconChartCircles className="icon" width={15} />
          <label>Academy Stats</label>
        </Box>
        <Box
          component={NavLink}
          to={ADMIN_CONTESTS_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconAward className="icon" width={15} />
          <label>Tävlingar</label>
        </Box>
        <Box
          component={NavLink}
          to={EXERCISE_COMMENTS_PATH}
          sx={(theme) => ({
            ".icon": {
              color: theme.colors.bluegray[5],
            },
            "&.active .icon": {
              color: theme.colors.blue[5],
            },
          })}
        >
          <IconMessage className="icon" width={15} />
          <label>Övningskommentarer</label>
        </Box>
        {isAllowedBilling && (
          <Box
            component={NavLink}
            to={ADMIN_BILLING_PATH}
            sx={(theme) => ({
              ".icon": {
                color: theme.colors.bluegray[5],
              },
              "&.active .icon": {
                color: theme.colors.blue[5],
              },
            })}
          >
            <IconFileInvoice className="icon" width={15} />
            <label>Fakturering</label>
          </Box>
        )}
      </SubMenuContent>
    </SubMenuWrapper>
  );
};

export const BigNumber = ({
  label,
  number,
}: {
  label: string;
  number: string;
}) => (
  <BigNumberWrapper>
    <label>{label}</label>
    <h1>{number}</h1>
  </BigNumberWrapper>
);
