import {
  Paper,
  Stack,
  Title,
  Text,
  Skeleton,
  Select,
  Alert,
} from "@mantine/core";
import { Ticket } from "../TicketDetails.api";
import { hasRespondToAddress } from "../TicketDetails.helpers";
import { PriorityCode, TicketStatusCode } from "../../Tickets/Tickets.api";

interface TicketInformationProps {
  type?: string;
  ticket?: Ticket;
  onStatusChanged: (val: string) => void;
  onPriorityChanged: (val: string) => void;
  loading: boolean;
  isUpdateError: boolean;
}

const TicketInformation = (props: TicketInformationProps) => {
  const respondTo = hasRespondToAddress(props.ticket)
    ? props.ticket?.RespondTo
    : undefined;

  return (
    <Paper
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.bluegray[0],
      })}
    >
      <Stack>
        <Title order={5}>Information</Title>
        <Stack spacing="xs">
          <Text
            sx={(theme) => ({ color: theme.colors.bluegray[5] })}
            size="sm"
            weight="bold"
          >
            Typ
          </Text>
          <Text>
            {props.type || (
              <Skeleton height={12} width={180} mt={6} radius="sm" />
            )}
          </Text>
        </Stack>
        {props.ticket?.ID && (
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              ID
            </Text>
            <Text>{props.ticket.ID}</Text>
          </Stack>
        )}
        {props.ticket?.BugID && (
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Buggnummer
            </Text>
            <Text>{props.ticket.BugID}</Text>
          </Stack>
        )}
        {props.ticket?.AppVersion && (
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Version
            </Text>
            <Text>{props.ticket.AppVersion}</Text>
          </Stack>
        )}
        {respondTo && (
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Svara till
            </Text>
            <Text>{respondTo}</Text>
          </Stack>
        )}
        <Select
          label="Status"
          value={props.ticket?.Status?.toString() ?? ""}
          onChange={props.onStatusChanged}
          disabled={props.loading}
          data={[
            { label: "Open", value: TicketStatusCode.Open.toString() },
            { label: "Pending", value: TicketStatusCode.Pending.toString() },
            { label: "Resolved", value: TicketStatusCode.Resolved.toString() },
            { label: "Closed", value: TicketStatusCode.Closed.toString() },
          ]}
        />
        <Select
          label="Prioritet"
          value={props.ticket?.Priority?.toString() ?? ""}
          onChange={props.onPriorityChanged}
          disabled={props.loading}
          data={[
            { label: "Low", value: PriorityCode.Low.toString() },
            { label: "Medium", value: PriorityCode.Medium.toString() },
            { label: "High", value: PriorityCode.High.toString() },
            { label: "Urgent", value: PriorityCode.Urgent.toString() },
          ]}
        />
        {props.isUpdateError ? (
          <Alert color="red">Något gick fel vid uppdateringen</Alert>
        ) : undefined}
      </Stack>
    </Paper>
  );
};

export default TicketInformation;
