import { useEffect, useState } from "react";
import {
  AppShell,
  Navbar,
  Group,
  MediaQuery,
  Burger,
  useMantineTheme,
  Header,
  Container,
  Box,
  Title,
  Loader,
  Stack,
  ScrollArea,
  Button,
  Skeleton,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import Conversation from "./components/Conversation";
import BugLog from "./components/BugLog";
import SystemInfo from "./components/SystemInfo";
import Screenshot from "./components/Screenshot";
import TicketInformation from "./components/TicketInformation";
import UserDetails from "./components/UserDetails";
import SideBarGithub from "./components/SideBarGithub";
import SideBarOther from "./components/SideBarOther";
import SideBarExerciseDetails from "./components/SideBarExerciseDetails";
import MessageEditor from "./components/MessageEditor";
import NoReplayWrapper from "./components/NoReplayWrapper";
import {
  Message,
  MessageTypeReply,
  Ticket,
  useBugConsole,
  useBugReplay,
  useBugScreenshot,
  useBugSyllabusInfo,
  usePrevLog,
  useShowTicket,
  useUpdateLastSeenReply,
  useUpdateTicket,
} from "./TicketDetails.api";
import { useGetAllOrganizations } from "../Organizations/Organizations.api";
import { useGetAllSchools } from "../../../../api/admin/allSchools";
import { Organization } from "../../../../types/organization.types";
import { School } from "../../../../types/school.types";
import { base64ToArrayBuffer } from "../../../../helpers/universal.helper";
import { IconCloudDownload } from "@tabler/icons";
import {
  downloadFileFromBase64,
  downloadStringAsLog,
} from "../../../../helpers/strings.helper";

function TicketDetails() {
  const theme = useMantineTheme();
  const { ticketId } = useParams<Record<string, string | undefined>>();

  const [opened, setOpened] = useState(false);
  const [ticket, setTicket] = useState<Ticket>();
  const [isBugReport, setIsBugReport] = useState(false);
  const [bugType, setBugType] = useState<string>();

  const [organization, setOrganization] = useState<Organization>();
  const [school, setSchool] = useState<School>();

  const updateLastSeenReply = useUpdateLastSeenReply();
  const showTicketCallback = (data: Ticket) => {
    const messages = [...data.Conversation];
    const reply = messages
      .reverse()
      .find(
        (msg: Message) => !msg.Agent.Internal && msg.Type === MessageTypeReply
      );
    if (reply) {
      // wait a little while after data returned before
      // setting reply as read.
      setTimeout(() => {
        updateLastSeenReply.mutate({ TicketID: data.ID, MessageID: reply.ID });
      }, 5000);
    }
  };

  const ticketQuery = useShowTicket(ticketId ?? "", showTicketCallback);
  const organizationsQuery = useGetAllOrganizations();
  const schoolsQuery = useGetAllSchools();
  const updateTicket = useUpdateTicket();

  useEffect(() => {
    if (ticketQuery.isSuccess) {
      const ticket = ticketQuery.data;
      if ("BugID" in ticket) {
        setIsBugReport(true);
        setBugType("Buggrapport");
      } else {
        setIsBugReport(false);
        setBugType("Feedback");
      }
      setOrganization(
        organizationsQuery.data?.map[ticketQuery.data?.User.OrganizationID]
      );
      setSchool(schoolsQuery.data?.map[ticketQuery.data?.User.SchoolID]);
      setTicket(ticket);
    }
  }, [
    ticketQuery.isSuccess,
    ticketQuery.data,
    organizationsQuery.data,
    schoolsQuery.data,
  ]);

  const bugReplayQuery = useBugReplay(ticket?.HasReplay, ticket?.BugID);
  const bugScreenshotQuery = useBugScreenshot(ticket?.BugID);
  const bugConsoleQuery = useBugConsole(ticket?.BugID);
  const prevLogQuery = usePrevLog(ticket?.BugID);
  const bugSyllabusQuery = useBugSyllabusInfo(ticket?.PublicExerciseUUID);

  const replayBlob =
    ticket?.HasReplay && bugReplayQuery.isSuccess
      ? new Blob([base64ToArrayBuffer(bugReplayQuery.data)], {
          type: "application/octet-stream",
        })
      : undefined;
  const replayBlobUrl = replayBlob
    ? URL.createObjectURL(replayBlob)
    : undefined;

  const onStatusChanged = (val: string) => {
    ticket &&
      updateTicket.mutate({
        ID: ticket.ID,
        Status: parseInt(val),
        Priority: ticket.Priority,
      });
    setTicket((prev) => {
      if (prev) {
        prev.Status = parseInt(val);
      }
      return prev;
    });
  };

  const onPriorityChanged = (val: string) => {
    ticket &&
      updateTicket.mutate({
        ID: ticket.ID,
        Status: ticket.Status,
        Priority: parseInt(val),
      });
    setTicket((prev) => {
      if (prev) {
        prev.Priority = parseInt(val);
      }
      return prev;
    });
  };

  const onMessageAdded = () => {
    ticketQuery.refetch();
  };

  document.title = `Ärende ${ticketQuery.data?.ID} – Skillster`;

  return (
    <>
      <AppShell
        navbarOffsetBreakpoint="sm"
        fixed
        sx={(theme) => ({
          backgroundColor: theme.colors.bluegray[0],
          padding: 0,
        })}
        header={
          <Header
            height={60}
            sx={(theme) => ({
              border: 0,
              backgroundColor: theme.colors.blue[7],
            })}
          >
            <Group align={"stretch"} sx={{ height: "100%" }} grow>
              <Group
                position="apart"
                sx={{
                  ".logo": {
                    width: 150,
                  },
                }}
              >
                <Box ml="xl">
                  <Title
                    order={2}
                    sx={{
                      color: theme.colors.blue[0],
                      display: "flex",
                      gap: 10,
                      alignItems: "center",
                    }}
                  >
                    {ticketQuery.isSuccess ? (
                      `Ärende ${ticketQuery.data?.ID}`
                    ) : (
                      <Loader size={"sm"} variant="bars" />
                    )}
                  </Title>
                </Box>
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.blue[0]}
                    mr="xl"
                  />
                </MediaQuery>
              </Group>
            </Group>
          </Header>
        }
        navbar={
          <Navbar width={{ sm: 300, lg: 300 }} hidden={!opened}>
            <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
              <Stack spacing={0}>
                <UserDetails
                  user={ticket?.User}
                  organization={organization}
                  school={school}
                />
                <TicketInformation
                  type={bugType}
                  ticket={ticket}
                  onStatusChanged={onStatusChanged}
                  onPriorityChanged={onPriorityChanged}
                  loading={updateTicket.isLoading}
                  isUpdateError={updateTicket.isError}
                ></TicketInformation>
                {isBugReport ? (
                  <SideBarExerciseDetails
                    course={bugSyllabusQuery.data?.Course}
                    exercise={bugSyllabusQuery.data?.Exercise}
                    lesson={bugSyllabusQuery.data?.Lesson}
                  />
                ) : undefined}
                <SideBarGithub
                  ticket={ticket}
                  onTicketUpdated={() => ticketQuery.refetch()}
                />
                <SideBarOther ticketID={ticket?.ID}>
                  {ticket?.BugNumber && ticket?.HasReplay && replayBlobUrl ? (
                    <Button<"a">
                      component="a"
                      href={replayBlobUrl}
                      download={`bug_${ticket?.BugNumber}.replay`}
                      target="_blank"
                      rel="noreferrer"
                      variant="light"
                      leftIcon={<IconCloudDownload size={16} />}
                    >
                      Ladda ner replay-fil
                    </Button>
                  ) : ticket?.BugNumber === 0 ? (
                    <section>
                      <NoReplayWrapper>
                        <Skeleton height={20} mt={6} radius="xl" />
                      </NoReplayWrapper>
                    </section>
                  ) : undefined}
                  {bugConsoleQuery.data && (
                    <Button
                      variant="light"
                      onClick={() =>
                        downloadStringAsLog(
                          bugConsoleQuery.data,
                          `log_${ticket?.BugNumber}.log`
                        )
                      }
                      leftIcon={<IconCloudDownload size={16} />}
                    >
                      Ladda ner log
                    </Button>
                  )}
                  {prevLogQuery.data && (
                    <Button
                      variant="light"
                      onClick={() =>
                        downloadFileFromBase64(
                          prevLogQuery.data,
                          `prevlog_${ticket?.BugNumber}.gz`
                        )
                      }
                      leftIcon={<IconCloudDownload size={16} />}
                    >
                      Ladda ner prevlog
                    </Button>
                  )}
                </SideBarOther>
              </Stack>
            </Navbar.Section>
          </Navbar>
        }
      >
        <Container size="lg" p={0}>
          <Stack spacing="xl">
            <Stack spacing="xs">
              <Conversation ticket={ticket} />
              <MessageEditor
                ticket={ticket}
                onMessageAdded={onMessageAdded}
              ></MessageEditor>
            </Stack>

            {isBugReport ? (
              <>
                <Screenshot screenshot={bugScreenshotQuery.data} />
                <BugLog console={bugConsoleQuery.data} />
                <SystemInfo systemInfo={ticket?.SystemInfo} />
              </>
            ) : undefined}
          </Stack>
        </Container>
      </AppShell>
    </>
  );
}

export default TicketDetails;
