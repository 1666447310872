import { Flex, Group, Stack, Text } from "@mantine/core";
import { Message, Ticket } from "../TicketDetails.api";
import {
  InitialsBadge,
  SkillsterBadge,
} from "../../../../global/Misc/Misc.components";
import { format } from "date-fns";
import { enGB, fi, sv } from "date-fns/locale";
import { fiLocale, svLocale } from "../../../../../helpers/universal.helper";
import styled from "styled-components";
import { User } from "../../../../../types/user.types";
import { parseMessageBody } from "../TicketDetails.helpers";
import { useState } from "react";
import { IconChevronDown, IconChevronUp, IconEyeOff } from "@tabler/icons";

export const DescriptionWrapper = styled.div`
  .dot-flashing {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primary.normal};
    color: ${(props) => props.theme.colors.primary.normal};
    animation: dotFlashing 0.5s infinite linear alternate;
    animation-delay: 0.25s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -12px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primary.normal};
    color: ${(props) => props.theme.colors.primary.normal};
    animation: dotFlashing 500ms infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 12px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primary.normal};
    color: ${(props) => props.theme.colors.primary.normal};
    animation: dotFlashing 500ms infinite alternate;
    animation-delay: 500ms;
  }

  @keyframes dotFlashing {
    0% {
      background-color: ${(props) => props.theme.colors.primary.normal};
    }
    50%,
    100% {
      background-color: ${(props) => props.theme.colors.primary.light};
    }
  }

  .inner {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 12px;
  }

  .bug-info,
  .bug-description {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.comp4.light};

    padding: 20px;
  }
  .bug-description {
    justify-content: flex-end;
    flex-grow: 1;
    .section {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 15px;
      max-width: 50%;
      &:not(.skillster):not(.skillster-private) {
        .bubble {
          .text:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 0;
            height: 0;
            border: 9px solid transparent;
            border-right-color: ${(props) => props.theme.colors.primary.normal};
            border-left: 0;
            margin-top: -9px;
            margin-left: -9px;
          }
        }
      }
      &.skillster {
        margin-left: auto;
        .bubble {
          .text {
            background-color: ${(props) => props.theme.colors.comp4.dark};
            :after {
              content: "";
              position: absolute;
              right: 0px;
              bottom: 10px;
              width: 0;
              height: 0;
              border: 9px solid transparent;
              border-left-color: ${(props) => props.theme.colors.comp4.dark};
              border-right: 0;
              margin-top: -9px;
              margin-right: -9px;
            }
          }
        }
      }
      &.skillster-private {
        margin-left: auto;
        .bubble {
          .text {
            background-color: ${(props) => props.theme.colors.comp4.dark};
            opacity: 0.8;
            :after {
              content: "";
              position: absolute;
              right: 0px;
              bottom: 10px;
              width: 0;
              height: 0;
              border: 9px solid transparent;
              border-left-color: ${(props) => props.theme.colors.comp4.dark};
              border-right: 0;
              margin-top: -9px;
              margin-right: -9px;
            }
          }
        }
      }
    }
    .bubble {
      .name {
        font-weight: bold;
        margin-bottom: 2px;
        font-size: 0.8em;
        color: rgba(255, 255, 255, 0.6);
      }
      .date {
        color: rgba(255, 255, 255, 0.6);
        text-align: right;
        margin-top: 2px;
        margin-bottom: 5px;
        font-size: 0.8em;
      }
      &.dots {
        .text {
          padding: 20px 30px;
          min-width: 0;
        }
      }
      .text {
        position: relative;
        font-weight: 600;
        font-size: 1em;
        padding: 9px 13px 3px 13px;
        min-width: 250px;
        border-radius: ${(props) => props.theme.border.radius.large};
        background-color: ${(props) => props.theme.colors.primary.normal};
        color: white;
        box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.1);
      }
      ol,
      ul {
        margin-left: 2rem;
      }
      blockquote {
        color: rgba(255, 255, 255, 0.6);
        margin: 8px 0;
        padding: 0 0 0 8px;
        border-left: 2px solid rgba(255, 255, 255, 0.6);
      }
      .message-content a {
        color: white;
        text-decoration: underline;
      }
      .message-content blockquote a {
        color: rgba(255, 255, 255, 0.6);
      }
      .message-more {
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.8em;
        cursor: pointer;
      }
    }
  }
`;

interface ConversationProps {
  ticket?: Ticket;
}

const Conversation = (props: ConversationProps) => {
  return (
    <Stack>
      <DescriptionWrapper>
        <div className="inner">
          <section className="bug-description">
            {props.ticket?.User && props.ticket?.CreationTime ? (
              <div className="section">
                <InitialsBadge
                  inverted
                  className="badge"
                  user={props.ticket?.User}
                />
                {props.ticket?.Description === "" ? (
                  <div className="bubble dots">
                    <div className="text">
                      <div className="dot-flashing" />
                    </div>
                  </div>
                ) : (
                  <div className="bubble">
                    <div className="text">
                      <div className="name">
                        {props.ticket?.User.FirstName}{" "}
                        {props.ticket?.User.LastName}
                      </div>
                      <div
                        className="message-content"
                        dangerouslySetInnerHTML={{
                          __html: props.ticket?.DescriptionHtml ?? "",
                        }}
                      />
                      <div className="date">
                        {format(
                          new Date(props.ticket?.CreationTime),
                          "d MMM H:m",
                          {
                            locale: svLocale() ? sv : fiLocale() ? fi : enGB,
                          }
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {props.ticket?.User && props.ticket?.Conversation
              ? props.ticket?.Conversation?.map(
                  (conversation: Message, index: number) => (
                    <div
                      className={`section ${
                        conversation.Agent.Internal
                          ? conversation.Private
                            ? "skillster-private"
                            : "skillster"
                          : ""
                      }`}
                      key={index}
                    >
                      {!conversation.Agent.Internal && props.ticket?.User ? (
                        <InitialsBadge
                          inverted
                          className="badge"
                          user={props.ticket?.User}
                        />
                      ) : null}
                      <div className="bubble">
                        <div className="text">
                          <MessageContent
                            message={conversation}
                            user={props.ticket?.User}
                          />
                        </div>
                      </div>
                      {conversation.Agent.Internal && <SkillsterBadge />}
                    </div>
                  )
                )
              : null}
          </section>
        </div>
      </DescriptionWrapper>
    </Stack>
  );
};

interface MessageContentProps {
  message: Message;
  user?: User;
}

const MessageContent = (props: MessageContentProps) => {
  const content = parseMessageBody(props.message.BodyHtml);
  const [expanded, setExpanded] = useState(false);

  //`${props.ticket?.User?.FirstName} ${props.ticket?.User?.LastName}`
  const userName = props.message.Agent.Internal
    ? props.message.Agent.Name
    : props.user?.Email === props.message.Agent.Email
    ? `${props.user?.FirstName} ${props.user?.LastName}`
    : props.message.Agent.Email;

  return (
    <>
      <div className="name">
        <Group align="center" spacing="xs">
          {props.message.Private ? <IconEyeOff size={14} /> : undefined}
          <div>{userName}</div>
        </Group>
      </div>
      <div
        className="message-content"
        dangerouslySetInnerHTML={{
          __html: (expanded ? content.full_html : content.html) ?? "",
        }}
      />
      <Flex justify={content.full_html ? "space-between" : "flex-end"}>
        {content.full_html && (
          <Group
            className="message-more"
            spacing={6}
            onClick={() => setExpanded(!expanded)}
          >
            <Text>{expanded ? "Mindre" : "Mer"}</Text>
            {expanded ? (
              <IconChevronUp size={14}></IconChevronUp>
            ) : (
              <IconChevronDown size={14}></IconChevronDown>
            )}
          </Group>
        )}
        <div className="date">
          {format(new Date(props.message.CreationTime), "d MMM H:m", {
            locale: svLocale() ? sv : fiLocale() ? fi : enGB,
          })}
        </div>
      </Flex>
    </>
  );
};

export default Conversation;
