import { Center, Image, Skeleton, Stack, Title } from "@mantine/core";
import { useModals } from "@mantine/modals";

interface ScreenshotProps {
  screenshot: string;
}

const Screenshot = (props: ScreenshotProps) => {
  const modals = useModals();
  const openModal = () =>
    modals.openModal({
      withCloseButton: false,
      size: "100%",
      padding: "sm",
      children: (
        <Center>
          {props.screenshot ? (
            <Image
              radius="md"
              src={`data:image/jpeg;base64,${props.screenshot}`}
              alt="Screenshot"
            />
          ) : (
            <Skeleton height={607} radius="sm" />
          )}
        </Center>
      ),
      centered: true,
    });
  return (
    <Stack>
      <Title order={2}>Skärmdump</Title>
      {props.screenshot ? (
        <Image
          radius="md"
          src={`data:image/jpeg;base64,${props.screenshot}`}
          alt="Screenshot"
          onClick={openModal}
          withPlaceholder
          sx={{
            cursor: "pointer",
            transition: "transform 80ms ease-in-out",
            ":hover": {
              transform: "scale(1.003)",
              opacity: 0.9,
            },
          }}
        />
      ) : (
        <Skeleton height={607} radius="sm" />
      )}
    </Stack>
  );
};

export default Screenshot;
