import { List, Paper, SimpleGrid, Stack, Title } from "@mantine/core";

interface SystemInfoDetails {
  title: string;
  content: string[];
}

interface SystemInfoProps {
  systemInfo?: string;
}

const SystemInfo = (props: SystemInfoProps) => {
  if (!props.systemInfo) return null;

  const parsed = props.systemInfo
    .split("\n\n")
    .map((line: string) => line.replaceAll("----", ""));
  parsed.splice(-1);

  const info: SystemInfoDetails[] = parsed.map((line: string) => {
    const titleLength = line.indexOf("\n");
    const title = line.substring(0, titleLength);
    const content = line.substring(titleLength + 3, line.length).split("\n  ");
    return { title, content };
  });

  return (
    <Stack>
      <Title order={2}>Systeminformation</Title>
      <SimpleGrid
        breakpoints={[
          { minWidth: "sm", cols: 2 },
          { minWidth: "lg", cols: 3 },
        ]}
      >
        {info.map((info: SystemInfoDetails, index: number) => (
          <Paper p="md" shadow="sm" key={index}>
            <Title order={5}>{info.title}</Title>
            <List mt="xs">
              {info.content.map((content: string, indexContent: number) => (
                <List.Item key={indexContent}>{content}</List.Item>
              ))}
            </List>
          </Paper>
        ))}
      </SimpleGrid>
    </Stack>
  );
};

export default SystemInfo;
