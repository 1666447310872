import { Permission, User } from "../types/user.types";
import { Fragment } from "react";
import { trim, trimStart } from "lodash";
import {
  PERMISSION_CODE_COMMUNICATION_MESSAGES_READ,
  PERMISSION_CODE_COMMUNICATION_MESSAGES_WRITE,
  PERMISSION_CODE_COURSE_EDITOR_READ,
  PERMISSION_CODE_COURSE_EDITOR_WRITE,
  PERMISSION_CODE_PERMISSIONS_READ,
  PERMISSION_CODE_PERMISSIONS_WRITE,
} from "./constants";

export const firstChar = (text: string) => text.charAt(0);

export const trimCourseName = (name?: string) => {
  if (!name) return "";
  return name.replace(
    / - Upplåst| - Fri| - Linjär| - Linear| - Free| - Open| - Vapaa| - Lineaarinen| - Open| - Grund/g,
    ""
  );
};

export const trimInactive = (text?: string) => {
  if (!text) return "";
  return text.replace(/(-@@-)(.+)(-@@-)/g, "");
};

export const nameToInitials = (name: string): string =>
  name
    .split(" ")
    .map((word) => word[0])
    .join("");

const boldifyAndItalifyMarkdown = (string: string) => {
  const bold = string.replace(/(\*\*(.*?)\*\*)/gm, "<strong>$1</strong>");
  const italics = bold.replace(/(__(.*?)__)/gm, "<i>$1</i>");
  const removedMarkdown = (
    <p
      dangerouslySetInnerHTML={{ __html: italics.replace(/(\*\*|__)/gm, "") }}
    />
  );
  return removedMarkdown;
};

export const stringIncludesDeactivated = (str: string): boolean =>
  str.includes("-@@-DEACTIVATED-");

export const userIsDeactivated = (user: User): boolean =>
  stringIncludesDeactivated(user.Email);

export function parseReleaseNotes(raw: string): JSX.Element {
  const notes = raw.split("\n@ ");
  // notes.shift();
  return (
    <div>
      {notes.map((note: string, index) => {
        return (
          <div className="releaseNote" key={index}>
            {note.split("\n").map((row: string, index) => {
              if (index === 0)
                return <label key={index}>{trim(row.replace("@", ""))}</label>;
              if (row.startsWith("####"))
                return <h4 key={index}>{trimStart(row.slice(4))}</h4>;
              if (row.startsWith("###"))
                return <h3 key={index}>{trimStart(row.slice(3))}</h3>;
              if (row.startsWith("##"))
                return <h2 key={index}>{trimStart(row.slice(2))}</h2>;
              if (row.startsWith("#"))
                return <h1 key={index}>{trimStart(row.slice(1))}</h1>;
              if (row.startsWith("-") || row.startsWith("!"))
                return <li key={index}>{trimStart(row.slice(1))}</li>;
              if (trim(row).length === 0) return "";
              return (
                <Fragment key={index}>
                  {boldifyAndItalifyMarkdown(trim(row))}
                </Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export const incrementString = (text: string) =>
  text === ""
    ? text
    : /^\d+$/.test(text.charAt(text.length - 1))
    ? text.replace(/(\d*)$/, (_, t) => (+t + 1).toString())
    : `${text} 2`;

export const isEmptyOrOnlySpaces = (string: string): boolean =>
  string.replace(/\s+/g, "") === "";

export function permissionToReadable(permission: Permission): string {
  switch (permission.Code) {
    case PERMISSION_CODE_PERMISSIONS_READ:
      return "Se användares behörigheter";
    case PERMISSION_CODE_PERMISSIONS_WRITE:
      return "Ändra användares behörigheter";
    case PERMISSION_CODE_COURSE_EDITOR_READ:
      return "Se skapade kurser";
    case PERMISSION_CODE_COURSE_EDITOR_WRITE:
      return "Kurseditorn";
    case PERMISSION_CODE_COMMUNICATION_MESSAGES_READ:
      return "Se templates för mejlutskick";
    case PERMISSION_CODE_COMMUNICATION_MESSAGES_WRITE:
      return "Ändra templates för mejlutskick";
  }
  return permission.Code;
}

export function getCloudinaryVehicleUrl(
  id: string,
  width?: number,
  height?: number,
  aspectRatio?: string
) {
  const base = process.env.REACT_APP_CLOUDINARY_BASE ?? "";
  const breakPoint = "upload";
  const part1 = base.slice(0, base.indexOf(breakPoint) + breakPoint.length);
  const part2 = base.slice(
    base.indexOf(breakPoint) + breakPoint.length,
    base.length
  );
  const ar = aspectRatio ?? "16:9";
  let transformation = width || height ? `/ar_${ar},c_fill,` : "";
  if (width) transformation += `w_${width}`;
  if (width && height) transformation += ",";
  if (height) transformation += `h_${height}`;
  return `${part1}${transformation}${part2}/${id}`;
}

export function getCloudinaryExerciseUrl(
  id: string,
  width?: number,
  height?: number,
  aspectRatio?: string,
  customTransformation?: string
) {
  const base = process.env.REACT_APP_CLOUDINARY_BASE_EXERCISE ?? "";
  const breakPoint = "upload";
  const part1 = base.slice(0, base.indexOf(breakPoint) + breakPoint.length);
  const part2 = base.slice(
    base.indexOf(breakPoint) + breakPoint.length,
    base.length
  );
  const ar = aspectRatio ?? "16:9";
  const improve = "e_improve:50,";
  let transformation =
    width || height ? `/ar_${ar},c_fill,${improve}` : improve;
  if (width) transformation += `w_${width}`;
  if (width && height) transformation += ",";
  if (height) transformation += `h_${height}`;
  if (customTransformation) transformation += `,${customTransformation}`;
  return `${part1}${transformation}${part2}/${id}`;
}

export function getCloudinaryLectureUrl(
  lectureUuid: string,
  imageId: string,
  width?: number,
  height?: number,
  aspectRatio?: string,
  customTransformation?: string
) {
  const base = process.env.REACT_APP_CLOUDINARY_BASE_LECTURES ?? "";
  const breakPoint = "upload";
  const part1 = base.slice(0, base.indexOf(breakPoint) + breakPoint.length);
  const part2 = base.slice(
    base.indexOf(breakPoint) + breakPoint.length,
    base.length
  );
  const ar = aspectRatio ?? "16:9";
  let transformation = `/ar_${ar},c_fill`;
  if (width) transformation += `,w_${width}`;
  if (width && height) transformation += ",";
  if (height) {
    if (width) transformation += `h_${height}`;
    else transformation += `,h_${height}`;
  }
  if (customTransformation) transformation += `,${customTransformation}`;
  return `${part1}${transformation}${part2}/${lectureUuid}/${imageId}`;
}

export function expandVersionNumber(version: string): string {
  return version
    .split(".")
    .map((n) => +n + 100000)
    .join(".");
}

export const START_CACHE_BASE_PATH = "skillster-start-search-cache-";

export function createSlug(input: string): string {
  return input
    .toLowerCase()
    .replace(/å/g, "a")
    .replace(/ä/g, "a")
    .replace(/ö/g, "o")
    .replace(/[^a-z0-9-]+/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "");
}

export function downloadStringAsLog(text: string, filename: string) {
  const blob = new Blob([text], { type: "text/plain" });

  const anchor = document.createElement("a");
  anchor.download = filename;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.style.display = "none";

  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(anchor.href);
}

export function downloadFileFromBase64(base64Data: string, filename: string) {
  const blob = base64ToBlob(base64Data, "application/gzip");

  const anchor = document.createElement("a");
  anchor.download = filename;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.style.display = "none";

  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(anchor.href);
}

function base64ToBlob(base64Data: string, type: string) {
  const binaryString = atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: type });
}
