import { Button, Modal, Space, Title } from "@mantine/core";
import { IconZoomIn } from "@tabler/icons";
import { useState } from "react";
import { LazyLog } from "react-lazylog";
import styled from "styled-components";

const LazyLogWrapper = styled.div`
  &.window {
    position: relative;
    margin-top: -50px;
    width: 90%;
    height: 80%;
    button.close {
      position: absolute;
      top: 10px;
      left: 10px;
      .icon {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        .fill {
          fill: white;
        }
      }
    }
  }
  input.react-lazylog-searchbar-input {
    padding: 20px 10px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

interface BugLogProps {
  console?: string;
}

const BugLog = (props: BugLogProps) => {
  const [open, setOpen] = useState(false);
  if (!props.console) return null;

  return (
    <>
      <Modal
        opened={open ?? false}
        onClose={() => setOpen(false)}
        withCloseButton={false}
        size={"80vw"}
        centered
        styles={{
          modal: { padding: 0 },
        }}
      >
        <div style={{ position: "relative", height: "80vh", padding: 0 }}>
          <LazyLog
            selectableLines
            caseInsensitive={true}
            enableSearch
            text={props.console}
          />
        </div>
      </Modal>
      <LazyLogWrapper>
        <div className="header">
          <Title order={2}>Console</Title>
          <Button
            onClick={() => setOpen(true)}
            variant="light"
            leftIcon={<IconZoomIn width={18} />}
          >
            Förstora
          </Button>
        </div>
        <Space h="md" />
        <LazyLog
          selectableLines
          caseInsensitive={true}
          enableSearch
          height={700}
          text={props.console}
        />
      </LazyLogWrapper>
    </>
  );
};

export default BugLog;
