import {
  Alert,
  Box,
  Button,
  CloseButton,
  Group,
  Paper,
  Select,
  Skeleton,
  Stack,
  Textarea,
  TextInput,
  Title,
  Text,
  Flex,
  Tooltip,
  Anchor,
  ActionIcon,
  Loader,
} from "@mantine/core";
import githubIcon from "../assets/github-mark-white.png";
import { useState } from "react";
import { useModals } from "@mantine/modals";
import {
  Issue,
  Repo,
  Ticket,
  useCreateIssue,
  useLinkIssue,
  useListProjects,
  useListRepos,
  useUnlinkIssue,
} from "../TicketDetails.api";
import { ErrorResponse } from "../../../../../helpers/requests.helper";
import {
  useGithubAuthMutation,
  useRevokeGithubAuth,
} from "../../../../../api/admin/useGithubOAuth";
import { useTranslation } from "react-i18next";
import { IconFilePlus, IconLink, IconLogout, IconUnlink } from "@tabler/icons";

interface CreateIssueModalProps {
  repos: Repo[];
  ticket: Ticket;
  onSuccess: () => void;
  onClose: () => void;
}

const CreateIssueModal = (props: CreateIssueModalProps) => {
  const { t } = useTranslation();
  const description = `Länk: 
${window.location.href}

Beskrivning:
${props.ticket.Description} 
`;

  const [issue, setIssue] = useState({
    TicketID: props.ticket.ID,
    Repo: props.repos[0].Name,
    Title: `Ärende #${props.ticket.ID}`,
    Description: description,
    ProjectID: "",
  });

  const listProjectsQuery = useListProjects();

  const createIssueMutation = useCreateIssue(() => {
    props.onSuccess();
    props.onClose();
  });

  const updateIssue = (val: any) => {
    setIssue((prev) => {
      const newVal = { ...prev, ...val };
      return newVal;
    });
  };

  const createIssue = () => {
    createIssueMutation.mutate(issue);
  };

  const simulatorProject =
    listProjectsQuery.data?.Projects.filter(
      (p) => p.Name.toLowerCase() === "simulator"
    ).map((p) => {
      return {
        value: p.Id,
        label: p.Name,
        group: "Föreslaget projekt",
      };
    }) ?? [];

  const allProjects =
    listProjectsQuery.data?.Projects.filter(
      (p) => p.Name.toLowerCase() !== "simulator"
    )
      .map((p) => {
        return {
          value: p.Id,
          label: p.Name,
          group: "Övriga projekt",
        };
      })
      .sort((p1, p2) => {
        if (p1.label < p2.label) return -1;
        if (p1.label > p2.label) return 1;
        return 0;
      }) ?? [];

  const projectSelectData = [...simulatorProject, ...allProjects];

  return (
    <Stack>
      <Select
        label="Repo"
        data={props.repos.map((x) => x.Name)}
        value={issue.Repo}
        onChange={(repoName: string) => {
          updateIssue({ Repo: repoName });
        }}
      ></Select>
      <Select
        label="Projekt"
        nothingFound="Inga projekt tillgängliga"
        placeholder={
          listProjectsQuery.isLoading
            ? "Hämtar projekt"
            : "Välj projekt (valfritt)"
        }
        data={projectSelectData}
        value={issue.ProjectID}
        onChange={(projectID: string) => {
          updateIssue({ ProjectID: projectID });
        }}
        icon={listProjectsQuery.isLoading && <Loader size={14} />}
        disabled={listProjectsQuery.isError}
        error={
          listProjectsQuery.isError && "Fel uppstod när projekt skulle hämtas"
        }
        clearable
      ></Select>
      <TextInput
        label="Titel"
        value={issue.Title}
        placeholder="Ange titel"
        autoFocus
        rightSection={
          issue.Title && (
            <CloseButton
              size={16}
              variant="transparent"
              onClick={() => updateIssue({ Title: "" })}
            />
          )
        }
        onChange={(event: any) => {
          updateIssue({ Title: event.target.value });
        }}
      ></TextInput>
      <Textarea
        label="Beskrivning"
        placeholder="Lägg till beskrivning"
        rightSection={
          issue.Description && (
            <Box style={{ alignItems: "flex-start", marginTop: 12 }}>
              <CloseButton
                size={16}
                variant="transparent"
                onClick={() => updateIssue({ Description: "" })}
              />
            </Box>
          )
        }
        sx={{
          ".mantine-Textarea-rightSection": {
            alignItems: "flex-start", // Align items to the start (top)
          },
        }}
        value={issue.Description}
        minRows={10}
        onChange={(event: any) => {
          updateIssue({ Description: event.target.value });
        }}
      ></Textarea>
      <Group position="right">
        <Button variant="light" onClick={props.onClose}>
          Avbryt
        </Button>
        <Button onClick={createIssue} loading={createIssueMutation.isLoading}>
          Skapa
        </Button>
      </Group>
      {createIssueMutation.isError && (
        <Alert color="red">{t("common:toasts.genericError")}</Alert>
      )}
    </Stack>
  );
};

interface LinkIssueModalProps {
  repos: Repo[];
  ticketID: number;
  onSuccess: () => void;
  onClose: () => void;
}

const LinkIssueModal = (props: LinkIssueModalProps) => {
  const [repo, setRepo] = useState(props.repos[0].Name);
  const [issueID, setIssueID] = useState("");

  const linkIssueMutation = useLinkIssue(() => {
    props.onSuccess();
    props.onClose();
  });

  const linkIssue = () => {
    linkIssueMutation.mutate({
      TicketID: props.ticketID,
      Repo: repo,
      IssueID: parseInt(issueID),
    });
  };

  return (
    <Stack>
      <Select
        label="Repo"
        data={props.repos.map((x) => x.Name)}
        value={repo}
        onChange={(event: any) => setRepo(event.target.value)}
      ></Select>
      <TextInput
        label="Issue ID"
        value={issueID}
        placeholder="Ange issue #"
        autoFocus
        onChange={(event: any) => {
          setIssueID(event.target.value);
        }}
      ></TextInput>
      <Group position="right">
        <Button variant="light" onClick={props.onClose}>
          Avbryt
        </Button>
        <Button onClick={linkIssue} loading={linkIssueMutation.isLoading}>
          Länka
        </Button>
      </Group>
      {linkIssueMutation.isError && (
        <Alert color="red">Något gick fel. Försök igen!</Alert>
      )}
    </Stack>
  );
};

interface IssueLinkProps {
  ticketID: number;
  issue: Issue;
  onUnlinkIssue: () => void;
}

const IssueLink = (props: IssueLinkProps) => {
  const modals = useModals();

  const unlinkIssueMutation = useUnlinkIssue(() => {
    props.onUnlinkIssue();
  });

  const unlinkIssue = () => {
    unlinkIssueMutation.reset();
    modals.openConfirmModal({
      title: "Ta bort länk till issue",
      children: (
        <Text>
          {"Kopplingen mellan ärendet och issuet kommer att tas bort " +
            "(issuet kommer att finnas kvar i GitHub). " +
            "Vill du fortsätta?"}
        </Text>
      ),
      labels: { confirm: "Fortsätt", cancel: "Avbryt" },
      onConfirm: () => {
        unlinkIssueMutation.mutate({
          TicketID: props.ticketID,
          IssueID: props.issue.ID,
        });
      },
    });
  };

  return (
    <>
      <Flex justify="space-between" align="center">
        <Tooltip label={props.issue.Title} position="top-start">
          <Anchor href={props.issue.URL} target="_blank" rel="noreferrer">
            {`Gå till issue #${props.issue.ID}`}
          </Anchor>
        </Tooltip>
        <Tooltip label="Ta bort länk">
          <ActionIcon
            variant="subtle"
            onClick={unlinkIssue}
            disabled={unlinkIssueMutation.isLoading}
          >
            <IconUnlink size={18} />
          </ActionIcon>
        </Tooltip>
      </Flex>
      {unlinkIssueMutation.isError && (
        <Alert color="red">Något gick fel!</Alert>
      )}
    </>
  );
};

interface SideBarGithubProps {
  ticket?: Ticket;
  onTicketUpdated: () => void;
}

const SideBarGithub = (props: SideBarGithubProps) => {
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState("");
  const modals = useModals();

  const listReposQuery = useListRepos({
    Enabled: !!props.ticket,
    OnSuccess: () => {
      setLoading(false);
      setAuthorized(true);
      setError("");
    },
    OnError: (err: ErrorResponse) => {
      setLoading(false);
      if (err.Response?.Reason === "oauth.unauthorized") {
        setAuthorized(false);
        setError("");
      } else {
        setError("Något fel uppstod när inloggning skulle verifieras");
      }
    },
  });

  const connectToGithub = useGithubAuthMutation();
  const url: string | undefined = connectToGithub.data?.URL;
  if (!!url) window.open(url, "_self");

  const revokeGithubAuth = useRevokeGithubAuth(() => {
    setAuthorized(false);
  });

  const createIssueClicked = () => {
    if (props.ticket) {
      const modalId = modals.openModal({
        title: "Skapa issue",
        centered: true,
        closeOnClickOutside: false,
        children: (
          <CreateIssueModal
            repos={listReposQuery.data?.Repos ?? []}
            ticket={props.ticket}
            onSuccess={() => props.onTicketUpdated()}
            onClose={() => modals.closeModal(modalId)}
          ></CreateIssueModal>
        ),
      });
    }
  };

  const linkIssueClicked = () => {
    if (props.ticket) {
      const modalId = modals.openModal({
        title: "Länka befintligt issue",
        centered: true,
        closeOnClickOutside: false,
        children: (
          <LinkIssueModal
            repos={listReposQuery.data?.Repos ?? []}
            ticketID={props.ticket.ID}
            onClose={() => modals.closeModal(modalId)}
            onSuccess={() => props.onTicketUpdated()}
          />
        ),
      });
    }
  };

  const githubSection = authorized ? (
    <Stack spacing="xs">
      {listReposQuery.data?.Repos?.length ? (
        <>
          {props.ticket?.Issues?.length && (
            <Box mb="md">
              <Text
                sx={(theme) => ({ color: theme.colors.bluegray[5] })}
                size="sm"
                weight="bold"
              >
                Länkade issues
              </Text>
              <Stack spacing="xs">
                {props.ticket.Issues.map((issue) => (
                  <IssueLink
                    key={issue.ID}
                    ticketID={props.ticket?.ID ?? 0}
                    issue={issue}
                    onUnlinkIssue={props.onTicketUpdated}
                  />
                ))}
              </Stack>
            </Box>
          )}
          <Button
            variant="light"
            onClick={createIssueClicked}
            disabled={revokeGithubAuth.isLoading}
            leftIcon={<IconFilePlus size={16} />}
          >
            Skapa issue
          </Button>
          <Button
            variant="light"
            onClick={linkIssueClicked}
            disabled={revokeGithubAuth.isLoading}
            leftIcon={<IconLink size={16} />}
          >
            Länka issue
          </Button>
        </>
      ) : (
        <Alert>Behörighet till repos saknas</Alert>
      )}
      <Button
        color="red"
        variant="light"
        onClick={() => revokeGithubAuth.mutate()}
        loading={revokeGithubAuth.isLoading}
        leftIcon={<IconLogout size={16} />}
      >
        Logga ut från Github
      </Button>
    </Stack>
  ) : (
    <Button
      onClick={() => connectToGithub.mutate()}
      loading={connectToGithub.isLoading}
      leftIcon={<img alt="GitHub" src={githubIcon} width={18} height={18} />}
      sx={(theme) => ({
        backgroundColor: "#333",
        color: "#fff",
        "&:hover": {
          backgroundColor: theme.fn.darken("#333", 0.1),
        },
      })}
    >
      Logga in på GitHub
    </Button>
  );

  return (
    <Paper
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.bluegray[0],
      })}
    >
      <Stack spacing="xs">
        <Title order={5}>Github</Title>
        {!props.ticket || loading ? (
          <Skeleton height={12} width={180} mt={6} radius="sm" />
        ) : error ? (
          <Alert color="red">{error}</Alert>
        ) : (
          githubSection
        )}
      </Stack>
    </Paper>
  );
};

export default SideBarGithub;
