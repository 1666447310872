import { Paper, Stack, Title, Text } from "@mantine/core";
import CourseCard from "../../../../global/Cards/Course/CourseCard";
import { Course } from "../../../../../types/course.types";
import { SyllabusExercise, SyllabusLesson } from "../TicketDetails.api";

interface SideBarExerciseDetailsProps {
  course?: Course;
  exercise?: SyllabusExercise;
  lesson?: SyllabusLesson;
}

const SideBarExerciseDetails = (props: SideBarExerciseDetailsProps) => {
  if (!props.course || !props.lesson || !props.exercise) return null;

  return (
    <Paper
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.bluegray[0],
      })}
    >
      <Stack>
        <Title order={5}>Övningsdetaljer</Title>

        <CourseCard course={props.course} />
        <Stack spacing={4}>
          <Text size="sm" weight="bold">
            {props.lesson.Name !== ""
              ? props.lesson.Name
              : `Lektion ${props.lesson.LessonIndex}`}
            , övning {props.exercise.ExerciseIndex}
          </Text>
          <Text>{props.exercise.Name}</Text>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default SideBarExerciseDetails;
