import { useQuery } from "react-query";
import { apiGet } from "../../../../helpers/requests.helper";

export enum TicketStatusCode {
  Open = 0,
  Pending = 1,
  Resolved = 2,
  Closed = 3,
}

export const statusColors: Record<TicketStatusCode, string> = {
  [TicketStatusCode.Open]: "blue",
  [TicketStatusCode.Pending]: "yellow",
  [TicketStatusCode.Resolved]: "cyan",
  [TicketStatusCode.Closed]: "green",
};

export enum PriorityCode {
  Low = 1,
  Medium = 2,
  High = 3,
  Urgent = 4,
}

export const priorityColors: Record<PriorityCode, string> = {
  [PriorityCode.Low]: "rgba(54, 112, 214, 0.6)",
  [PriorityCode.Medium]: "rgba(8, 199, 154, 0.6)",
  [PriorityCode.High]: "rgba(214, 161, 54, 0.6)",
  [PriorityCode.Urgent]: "rgba(250, 82, 82, 0.6)",
};

export interface Ticket {
  ID: number;
  UserID: number;
  UserName: string;
  UserEmail: string;
  SchoolID: number;
  SchoolName: string;

  Subject: string;
  Description: string;
  Status: number;
  Priority: number;

  HasNewMessages: boolean;
  CreationTime: string;
  Tags: string[];

  // Bug report specific fields
  RespondTo: string;
  BugID: string;
  AppVersion: string;
}

interface TicketMetaData {
  current_page: number;
  first_page: number;
  last_page: number;
  page_size: number;
}

interface ListTicketResponse<T> {
  MetaData: TicketMetaData;
  Tickets: T[];
}

interface ListTicketsQueryProps {
  type: string;
  statuses: string[];
  priorities: string[];
  searchFilter: string;
  page: number;
}

export function useListTickets(props: ListTicketsQueryProps) {
  return useQuery<ListTicketResponse<Ticket>, Error>(
    [
      "list-tickets",
      props.type,
      props.statuses,
      props.priorities,
      props.searchFilter,
      props.page,
    ],
    async () => {
      return await apiGet("/tickets/list-tickets", {
        type: props.type,
        statuses:
          props.statuses?.length > 0
            ? props.statuses.map((code) => `${code}`)
            : "",
        priorities:
          props.priorities?.length > 0
            ? props.priorities.map((code) => `${code}`)
            : "",
        search_filter: props.searchFilter ?? "",
        page: props.page ?? undefined,
      });
    }
  );
}
