import { useDispatch } from "react-redux";
import { Organization } from "../../../../../types/organization.types";
import { School } from "../../../../../types/school.types";
import { User } from "../../../../../types/user.types";
import { changeOrganization } from "../../../../../actions";
import {
  ORGANIZATIONS_PATH,
  USERS_PATH,
} from "../../../../../navigation/Routes";
import {
  Anchor,
  Badge,
  Box,
  Group,
  Paper,
  Skeleton,
  Stack,
  Title,
  Tooltip,
  Text,
} from "@mantine/core";
import { InitialsBadge } from "../../../../global/Misc/Misc.components";

interface UserDetailsProps {
  user?: User;
  organization?: Organization;
  school?: School;
}

const UserDetails = (props: UserDetailsProps) => {
  const dispatch = useDispatch();

  function handleGoToUser() {
    if (props.organization && props.user) {
      dispatch(changeOrganization(props.organization, props.user.SchoolID));
      window.open(`${USERS_PATH}/${props.user.ID}`, "_blank");
    }
  }

  return (
    <Stack>
      <Paper
        p="md"
        sx={(theme) => ({
          backgroundColor: theme.colors.bluegray[0],
        })}
      >
        <Stack>
          <Title order={5}>Rapportör</Title>
          <Box
            onClick={handleGoToUser}
            sx={(theme) => ({
              backgroundColor: "white",
              padding: theme.spacing.sm,
              borderRadius: theme.radius.sm,
              ":hover": {
                boxShadow: [
                  `0 0 0 2px ${theme.colors.blue[5]}, ${theme.shadows.lg}`,
                ],
              },
              cursor: "pointer",
            })}
          >
            {props.user ? (
              <Group spacing="xs">
                <InitialsBadge user={props.user} />
                <Title order={5}>
                  {props.user.FirstName} {props.user.LastName}
                </Title>
              </Group>
            ) : (
              <Group spacing="xs">
                <Skeleton
                  height={38}
                  style={{ flexShrink: 0 }}
                  mr={10}
                  circle
                  radius="xl"
                />
                <Skeleton height={12} radius="xl" />
              </Group>
            )}
          </Box>
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Organisation
            </Text>
            <Group align="center" position="apart">
              {props.organization ? (
                <>
                  <Anchor
                    href={`${ORGANIZATIONS_PATH}/${props.organization.ID}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.organization.Name}
                  </Anchor>
                  <Tooltip label="Organisations-ID">
                    <Badge>{props.organization.ID}</Badge>
                  </Tooltip>
                </>
              ) : (
                <Skeleton height={12} width={180} mt={6} radius="sm" />
              )}
            </Group>
          </Stack>
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Skola
            </Text>
            <Group align="center" position="apart">
              {props.school ? (
                <>
                  <Anchor
                    href={`${ORGANIZATIONS_PATH}/${props.organization?.ID}/${props.school.ID}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.school.Name}
                  </Anchor>
                  <Tooltip label="Skol-ID">
                    <Badge>{props.school.ID}</Badge>
                  </Tooltip>
                </>
              ) : (
                <Skeleton height={12} width={160} mt={6} radius="sm" />
              )}
            </Group>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default UserDetails;
