import {
  Alert,
  Badge,
  Button,
  Flex,
  Group,
  Stack,
  TextInput,
  Title,
  useMantineTheme,
  Text,
} from "@mantine/core";
import { IconCornerUpLeft, IconNotes, IconX } from "@tabler/icons";
import {
  MessageTypeNotePrivate,
  MessageTypeReply,
  Ticket,
  useCreateMessage,
} from "../TicketDetails.api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RichTextEditor } from "@mantine/tiptap";
import TextEditor from "./TextEditor";
import {
  buildResponsePreviousThread,
  findReplyEmail,
} from "../TicketDetails.helpers";

interface MessageInputProps {
  ticket?: Ticket;
  onMessageAdded: () => void;
}

const MessageEditor = (props: MessageInputProps) => {
  const [messageType, setMessageType] = useState<string>();

  const createMessageMutation = useCreateMessage(() => {
    setMessageType("");
    props.onMessageAdded();
  });

  if (!props.ticket) {
    return <></>;
  }

  const replyDisabled = !props.ticket.Emails?.length;
  if (!messageType) {
    return (
      <>
        <Group position="right">
          <Button
            size="xs"
            variant="outline"
            leftIcon={<IconNotes size={20} />}
            onClick={() => setMessageType("note")}
            disabled={replyDisabled}
          >
            Skapa anteckning
          </Button>
          <Button
            size="xs"
            variant="outline"
            leftIcon={<IconCornerUpLeft size={20} />}
            onClick={() => setMessageType("reply")}
            disabled={replyDisabled}
          >
            Svara
          </Button>
        </Group>
      </>
    );
  }

  const onMessageCancel = () => {
    setMessageType("");
  };

  const onMessageSubmit = (val: any) => {
    const message = `<div>${val.message}</div>`; //editor html has no root, add one.
    props.ticket &&
      createMessageMutation.mutate({
        TicketID: props.ticket.ID,
        Type: val.type,
        Message: message,
        CcEmails: val.ccEmails,
      });
  };

  return messageType === "reply" ? (
    <ReplyEditor
      ticket={props.ticket}
      loading={createMessageMutation.isLoading}
      error={createMessageMutation.isError}
      onCancel={onMessageCancel}
      onSubmit={onMessageSubmit}
    />
  ) : (
    <NoteEditor
      loading={createMessageMutation.isLoading}
      error={createMessageMutation.isError}
      onCancel={onMessageCancel}
      onSubmit={onMessageSubmit}
    />
  );
};

interface NoteEditorProps {
  loading: boolean;
  error: boolean;
  onCancel: () => void;
  onSubmit: (note: any) => void;
}

const NoteEditor = (props: NoteEditorProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [note, setNote] = useState<string>();
  const topBar = (
    <RichTextEditor.Toolbar style={{ background: theme.colors.bluegray[2] }}>
      <Title order={5}>Ny anteckning</Title>
    </RichTextEditor.Toolbar>
  );

  const onNoteUpdate = (val: string) => {
    setNote(val);
  };

  const onSubmit = () => {
    props.onSubmit({ type: MessageTypeNotePrivate, message: note });
  };

  return (
    <Stack spacing="xs">
      <TextEditor
        initialContent={note}
        placeHolder="Skriv din anteckning..."
        topBar={topBar}
        onUpdate={onNoteUpdate}
      />
      <Group position="right">
        {props.error && (
          <Alert color="red">{t("common:toasts.genericError")}</Alert>
        )}
        <Button
          variant="outline"
          onClick={props.onCancel}
          disabled={props.loading}
        >
          Avbryt
        </Button>
        <Button onClick={onSubmit} disabled={!note} loading={props.loading}>
          Spara
        </Button>
      </Group>
    </Stack>
  );
};

interface ReplyEditorProps {
  ticket: Ticket;
  loading: boolean;
  error: boolean;
  onCancel: () => void;
  onSubmit: (reply: any) => void;
}

const ReplyEditor = (props: ReplyEditorProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const userName =
    props.ticket.User.FirstName + " " + props.ticket.User.LastName;

  const primaryEmail = findReplyEmail(props.ticket.Emails);

  const previousConversation = buildResponsePreviousThread(props.ticket);
  const [reply, setReply] = useState<string>(`
          <div>
            <p>Hej ${userName},</p>
            <p/>
            <p>Tack för ditt meddelande.</p>
            <p/>
            <p/>
            <p>Med vänlig hälsning,</p>
            <p>Skillster support</p>
            <p></p>
            ${previousConversation}
          </div>`);
  const [ccEmails, setCcEmails] = useState(
    props.ticket.Emails.filter((x) => x.Value !== primaryEmail.Value).map(
      (x) => x.Value
    )
  );
  const topBar = (
    <>
      <RichTextEditor.Toolbar style={{ background: theme.colors.bluegray[2] }}>
        <Title order={5}>Nytt svar</Title>
        <Flex justify="flex-end" style={{ flexGrow: 1 }}>
          <Group>
            <Text>Skickas till:</Text>
            <Text style={{ fontWeight: "bold" }}>{primaryEmail.Value}</Text>
          </Group>
        </Flex>
      </RichTextEditor.Toolbar>
      <RichTextEditor.Toolbar>
        <Group>
          <Title order={5}>Cc: </Title>
          <CcInput
            ccEmails={ccEmails}
            onCcUpdate={(val: string[]) => setCcEmails(val)}
          ></CcInput>
        </Group>
      </RichTextEditor.Toolbar>
    </>
  );

  const onNoteUpdate = (val: string) => {
    setReply(val);
  };

  const onSubmit = () => {
    props.onSubmit({
      type: MessageTypeReply,
      message: reply,
      ccEmails: ccEmails,
    });
  };

  return (
    <Stack spacing="xs">
      <TextEditor
        initialContent={reply}
        topBar={topBar}
        onUpdate={onNoteUpdate}
      />
      <Group position="right">
        {props.error && (
          <Alert color="red">{t("common:toasts.genericError")}</Alert>
        )}
        <Button
          variant="outline"
          onClick={props.onCancel}
          disabled={props.loading}
        >
          Avbryt
        </Button>
        <Button onClick={onSubmit} loading={props.loading}>
          Skicka
        </Button>
      </Group>
    </Stack>
  );
};

interface CcInputProps {
  ccEmails: string[];
  onCcUpdate: (val: string[]) => void;
}

const CcInput = (props: CcInputProps) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const onChangeHandler = (event: any) => {
    const val = event.target.value;
    if (
      val &&
      props.ccEmails.some((x) => x.toLowerCase() === val.toLowerCase())
    ) {
      setError("duplicate");
    } else {
      setError("");
    }
    setInputValue(val);
  };

  const onKeyDownHandler = (event: any) => {
    if (event.key === "Enter" && !error) {
      if (
        inputValue &&
        !props.ccEmails.some((x) => x.toLowerCase() === inputValue)
      ) {
        props.onCcUpdate([...props.ccEmails, inputValue]);
        setInputValue("");
      }
    }
  };

  const onRemoveEmail = (val: string) => {
    props.onCcUpdate(props.ccEmails.filter((x) => x !== val));
  };

  return (
    <Group spacing="xs">
      {props.ccEmails.map((x) => (
        <Badge
          key={x}
          radius="sm"
          sx={(theme) => ({
            color: theme.colors.bluegray[5],
            textTransform: "lowercase",
          })}
        >
          <Flex align="center">
            {x}
            <IconX
              size={12}
              style={{ marginLeft: 10, cursor: "pointer" }}
              onClick={() => onRemoveEmail(x)}
            />
          </Flex>
        </Badge>
      ))}
      <TextInput
        error={!!error}
        placeholder="Lägg till email..."
        value={inputValue}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        sx={{
          width: 200,
          input: {
            border: "none",
            boxShadow: "none",
            backgroundColor: "transparent !important",
          },
        }}
      />
    </Group>
  );
};

export default MessageEditor;
