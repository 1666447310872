import { Button, Paper, Stack, Title } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons";
import { ReactNode } from "react";
import { getFreshDeskUrl } from "../../../../../helpers/universal.helper";

interface SideBarOtherProps {
  ticketID?: number;
  children?: ReactNode;
}

const SideBarOther = (props: SideBarOtherProps) => {
  return (
    <Paper
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.bluegray[0],
      })}
    >
      <Stack spacing="xs">
        <Title order={5}>Övrigt</Title>
        {props.children}
        {props.ticketID && (
          <Button<"a">
            component="a"
            href={getFreshDeskUrl(props.ticketID)}
            target="_blank"
            rel="noreferrer"
            variant="light"
            leftIcon={<IconExternalLink size={15} />}
          >
            Öppna i Freshdesk
          </Button>
        )}
      </Stack>
    </Paper>
  );
};

export default SideBarOther;
